import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../Images/Logo.png";
import Hero1 from "./TheSImages/1.png";
import Hero2 from "./TheSImages/2.png";
import Amenities from "./TheSImages/4.png";
import Gallery1a from "./TheSImages/Gallery1a.png";
import Gallery2a from "./TheSImages/Gallery2a.png";
import Gallery3a from "./TheSImages/Gallery3a.png";
import Gallery4a from "./TheSImages/Gallery4a.png";
import Gallery5a from "./TheSImages/Gallery5a.png";
import Location from "./TheSImages/location.png";
import DA from "./TheSImages/DA.png";
import pdf from "./TheSImages/pdf.png";
import touch from "./TheSImages/touch.png";
import info from "./TheSImages/info.png";
import Footer from "../WhyDubaiComps/Footer";
import useScrollToTop from "../Scroll";

const TheS = () => {
  const alternateImages = [Gallery1a, Gallery2a, Gallery3a, Gallery4a];
  const NextArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &gt;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &lt;
    </div>
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <div className="relative w-full ">
        {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>    <section className="w-full h-full">
          <img src={Hero1} alt="" className="w-full h-full object-cover" />
        </section>
      </div>

      <div className="bg-[#F5F5F5]">
        <div className="py-5 flex justify-end w-10/12 mx-auto relative">
          <button
            onClick={togglePopup}
            className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl text-white"
          >
            Pricing Plan
          </button>
          {isOpen && (
            <div className="absolute top-full z-10 right-0 mt-2 bg-white shadow-lg rounded-lg p-4 w-96">
              <h3 className="text-xl font-bold mb-2">Payment Plans</h3>
              <ul className="list-disc pl-5">
                <li className="bg-transparent text-black text-lg">
                  40/60 Payment Plan
                </li>
                <li className="bg-transparent text-black text-lg">
                  Price range 1mn-5mn AED
                </li>
              </ul>
            </div>
          )}
        </div>
        <h1 className="text-3xl font-semibold text-center">Green vista</h1>
        <p className="w-10/12 mx-auto text-2xl text-left my-10">
          Discover a haven of peace and serenity at Green Vistas, where modern
          living harmonizes with nature. This exceptional residential community
          offers a perfect blend of comfort, convenience, and sustainability.
          Immerse yourself in a lifestyle that prioritizes well-being and
          tranquility, surrounded by lush greenery and thoughtfully designed
          spaces. Experience the joy of effortless living as you embrace a
          harmonious connection with nature, right at your doorstep.
        </p>
        <div className="bg-custom-gradient">
          <img className="mx-auto pb-6" src={Amenities} alt="" />
        </div>
      </div>
      <div className="bg-[#f5f5f5]">
        <div className="flex w-11/12 mx-auto py-10 flex-col items-center">
          <h1 className="my-10 text-2xl font-semibold">Gallery</h1>
          <div className="w-full">
            <Slider {...sliderSettings}>
              {alternateImages.map((image, index) => (
                <div key={index} className="w-full h-auto">
                  <img
                    src={image}
                    alt={`Alternate Imge ${index + 1}`}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <h2 className="text-2xl text-center my-10 font-bold mb-4">
        Location Advantages
      </h2>
      <div className="flex justify-between items-start p-4 ">
        <div className="ml-10 w-1/3 p-4 bg-white rounded ">
          <h2 className="ml-10 text-2xl font-bold mb-4">Connectivity</h2>
          <ul
            className=" list-disc pl-5 space-y-2"
            style={{ color: "#1FC7D4" }}
          >
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                12 minutes to Downtown Dubai
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                13 minutes to Burj Khalifa
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                15 minutes to Dubai Mall
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                14 minutes to Dubai International Financial Centre
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                20 minutes to Dubai Marina and Dubai International Airport
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Proximity to major highways (Sheikh Zayed Road and Al Khail
                Road)
              </p>
            </li>
          </ul>
        </div>

        <div className=" p-4 w-1/3 ml-36 flex justify-center items-center bg-white rounded ">
          <img src={Location} alt="Placeholder " className="rounded" />
        </div>

        <div className="ml-10 p-4 bg-white rounded ">
          <h2 className="ml-10 w-1/3 text-2xl font-bold mb-4 ">Neighborhood</h2>
          <ul
            className=" list-disc pl-5 space-y-2"
            style={{ color: "#1FC7D4" }}
          >
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Vibrant cultural and entertainment hubs offering theaters, art
                galleries, and live performance venues.
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Nearby luxury hotels renowned for their world-class hospitality
                and amenities.
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Fine dining venues showcasing a diverse range of international
                cuisines.
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Upscale shopping malls featuring luxury brands and designer
                boutiques.
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Close proximity to recreational facilities such as parks and
                beachfront promenades.
              </p>
            </li>
            <li style={{ color: "inherit" }}>
              <p className="text-black text-xl my-5">
                Access to fitness centers, spas, and wellness retreats for
                health-conscious residents.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className=" bg-[#f5f5f5]">
        <h2 className="text-2xl my-10 font-bold mb-6 text-center">
          Design and Architecture
        </h2>

        <div className=" bg-[#f5f5f5] flex justify-center w-11/12 mx-auto ">
          <div className=" p-4 w-1/2  mr-10">
            <p className="text-xl text-black">
              Green Vistas embodies a contemporary architectural style that
              seamlessly blends with the natural surroundings. The design
              prioritizes open spaces, abundant natural light, and a focus on
              sustainability. Clean lines and modern aesthetics create a
              harmonious living environment, while eco-friendly features enhance
              the overall well-being of residents.Built with meticulous
              attention to detail, Green Vistas prioritizes quality and
              durability. The use of high-quality materials and advanced
              construction techniques ensures a secure and comfortable living
              environment.The interiors of Green Vistas are designed to create a
              soothing and inviting atmosphere. Modern finishes, spacious
              layouts, and ample natural light enhance the overall living
              experience.
            </p>
          </div>

          <div className=" bg-[#f5f5f5]">
            <img src={DA} alt="Placeholder" className="rounded  " />
          </div>
        </div>

        <button className="bg-[#D8B56D] text-white uppercasepx-6 py-3 flex bg-[#D8B56D] uppercase  text-white rounded-3xl mx-auto my-10 w-96 justify-center ">
          Download Brochure <img className="ml-3" src={pdf} alt="" />
        </button>
      </div>
      <div className="flex w-full  bg-[#000]  py-10">
        <div className="w-full rounded-l-2xl bg-white p-4 ">
          <div className=" w-10/12 mx-auto  p-6  ">
            <img alt="" src={touch} className=" mb-4" />
            <form className="pb-10">
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="NAME"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="EMAIL"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="PHONE NUMBER"
                />
              </div>

              <button
                className="bg-[#D8B56D] w-full text-white font-bold uppercase py-2 px-4 rounded "
                type="button"
              >
                SEND
              </button>
            </form>
            <img src={info} alt="" />
          </div>
        </div>

        <div className="  rounded-r-2xl h-[470px]  p-4 flex justify-center items-center">
          <div className=" p-6  rounded shadow-md">
          
            <div className="rounded overflow-hidden shadow-lg">
              <iframe
                className="h-[470px] w-[470px] rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.168505642546!2d55.173861210957384!3d25.096156677683474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b798cbfff8b%3A0x70a7f5e105a580e!2sDAMAC%20Smart%20Heights!5e0!3m2!1sen!2sus!4v1728393772385!5m2!1sen!2sus"
                allowFullScreen=""
                loading="lazy"
                title="map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TheS;
