import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../Images/Logo.png";
import Hero1 from "./IvyImages/1.png";
import Hero2 from "./IvyImages/2.png";
import Amenities from "./IvyImages/4.png";
import Gallery1a from "./IvyImages/Gallery1a.jpg";
import Gallery2a from "./IvyImages/Gallery2a.jpg";
import Gallery3a from "./IvyImages/Gallery3a.jpg";
import Gallery4a from "./IvyImages/Gallery4a.webp";
import Gallery5a from "./IvyImages/Gallery5a.jpg";
import BulletImage from "./IvyImages/Bullet.png";
import Location from "./IvyImages/location.png";
import DA from "./IvyImages/DA.png";
import pdf from "./IvyImages/pdf.png";
import touch from "./IvyImages/touch.png";
import info from "./IvyImages/info.png";
import Footer from "../../WhyDubaiComps/Footer";
// import Brochure from "./Ivy.pdf";

import useScrollToTop from "../../Scroll";

const Ivy = () => {
  useScrollToTop();

  const alternateImages = [
    Gallery1a,
    Gallery2a,
    Gallery3a,
    Gallery4a,
    Gallery5a,
  ];
  const NextArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &gt;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &lt;
    </div>
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const handleDownload = () => {
    const newErrors = {
      name: !formValues.name,
      email: !formValues.email,
      phone: !formValues.phone,
    };

    setErrors(newErrors);

    // If there are no errors, proceed with download
    if (!newErrors.name && !newErrors.email && !newErrors.phone) {
      const link = document.createElement("a");
      // link.href = Brochure; // Use the imported PDF file here
      link.download = "IvyBrochure.pdf"; // You can set the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("Download initiated");
      alert("Thanks for contacting us. Our team will be in touch with you soon.");
      setFormValues({
        name: "",
        email: "",
        phone: "",
      });
      setShowPopup(false);
    }
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openModal = () => {
    setIsGalleryOpen(true);
  };

  const closeModal = () => {
    setIsGalleryOpen(false);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <div className="relative w-full ">
        {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>    <div>
          {/* Section that triggers the popup */}
          <section className="w-full h-full cursor-pointer" onClick={openModal}>
            <img src={Hero1} alt="" className="w-full h-full object-cover" />
          </section>

          {/* Popup Modal */}
          {isGalleryOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
              <div className="relative w-11/12 max-w-6xl bg-white p-6">
                {/* Close Button */}
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  &#x2715; {/* Cross icon */}
                </button>

                {/* Slider */}
                 <Slider {...sliderSettings}>
                  {alternateImages.map((image, index) => (
                    <div key={index} className="w-full h-auto">
                      <img
                        src={image}
                        alt={`Alternate Iage ${index + 1}`}
                        className="w-full h-[90vh] "
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-5 flex justify-end w-10/12 mx-auto relative">
        <Link
          to={"/Samana#top"}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl mr-10 text-white"
        >
          GO Back
        </Link>
        <button
          onClick={togglePopup}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl text-white"
        >
          Pricing Plan
        </button>
        {isOpen && (
          <div className="absolute top-full z-10 right-0 mt-2 bg-white shadow-lg rounded-lg p-4 w-96">
            <h3 className="text-xl font-bold mb-2">Payment Plans</h3>
            <ul className="list-disc pl-5">
              <li className="bg-transparent text-black text-lg">
                15/85, (1% Monthly)
              </li>
              <li className="bg-transparent text-black text-lg">
                Down payment: 15% AED 674,000{" "}
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="bg-[#F5F5F5] pt-10">
        <h1 className="text-5xl font-semibold text-center">
        Ivy Gardens by Samana Developers        </h1>
        <p className="w-10/12 mx-auto text-2xl text-left my-10">
        Ivy Gardens by Samana Developers is a luxurious residential project situated in the heart of Dubailand. This exceptional development offers an idyllic blend of modern architecture and natural beauty, providing a serene retreat within Dubai’s vibrant urban environment. The apartments are thoughtfully designed with private pools and premium amenities, catering to individuals and families seeking a lifestyle of comfort and elegance. Ivy Gardens redefines urban living by creating a harmonious connection between contemporary design and nature-inspired elements, ensuring a peaceful and opulent experience for residents.

        </p>
        <div class="py-6">
  <div class="p-10 text-[#FFFFFF] bg-[#C69148]">
    <div
      class="flex flex-col text-center lg:text-start lg:flex-row lg:justify-center xl:justify-between lg:space-x-24 xl:px-32"
    >
      <div class="flex flex-col space-y-4">
        <h1 class="text-2xl font-bold">Location</h1>
        <li>
          <span class="text-base font-bold">Area :</span> Dubailand, Dubai
        </li>
        <li class="lg:w-[350px] xl:w-[450px]">
          <span class="text-base font-bold">Accessibilty :</span> Indulge in a luxurious lifestyle at Dubailand, Dubai, where world-class amenities and a peaceful ambiance come together seamlessly.
        </li>
      </div>
      <div class="flex flex-col space-y-4 py-3 lg:py-0">
        <h1 class="text-2xl font-bold">Property Type</h1>
        <li>
          <span class="text-base font-bold">unit Types :</span> Studio
        </li>
        <li>
          <span class="text-base font-bold">Unit Configuration :</span>
          studio , 1, 2, 3 bedrooms
        </li>
      </div>
    </div>
  </div>
</div>
 <div class="bg-gradient-to-b from-[#EED3A8] to-[#C69148]">
  <div class="py-7 lg:py-10">
    <h1 class="text-3xl lg:text-4xl font-semibold text-center">
      Amenities
    </h1>
  </div>
  <div class="flex flex-col items-center lg:flex-row lg:justify-between xl:justify-center xl:space-x-5 p-3 lg:p-5">
    <div
      class="p-2 rounded-lg h-[420px] w-[300px] lg:h-[420px] lg:w-[320px] xl:h-[400px] xl:w-[380px] bg-white"
    >
      <div class="p-2">
        <div
          class="space-y-2 p-4 h-[390px] w-[270px] lg:h-[370px] lg:w-[280px] xl:w-[350px] border-4 rounded-lg border-[#3B3A5D]"
        >
          <h1 class="text-2xl font-bold text-center lg:text-start xl:text-center">Recreational Facilities</h1>
          <div class="text-[#393939]">
            <span class="flex space-x-2 items-center">
              <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
              <p class="text-lg">Rooftop infinity pool</p>
            </span>
            <span class="flex space-x-2 items-center">
              <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
              <p class="text-lg">Private pools for select units</p>
            </span>
            <span class="flex space-x-2 items-center">
              <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
              <p class="text-lg">Outdoor cinema and BBQ area</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="py-5 lg:py-0">
      <div
        class="p-2 rounded-lg h-[420px] w-[300px] lg:h-[420px] lg:w-[320px] xl:h-[400px] xl:w-[380px] bg-white"
      >
        <div class="p-2">
          <div
            class="space-y-2 p-4 h-[390px] w-[270px] lg:h-[370px] lg:w-[280px] xl:w-[350px] border-4 rounded-lg border-[#3B3A5D]"
          >
            <h1 class="text-2xl font-bold text-center lg:text-start xl:text-center">Security Features</h1>
            <div class="text-[#393939]">
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">
                  24/7 security and CCTV surveillance
                </p>
              </span>
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">
                  Secure gated access
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-5 lg:py-0">
      <div
        class="p-2 rounded-lg h-[420px] w-[300px] lg:h-[420px] lg:w-[320px] xl:h-[400px] xl:w-[380px] bg-white"
      >
        <div class="p-2">
          <div
            class="space-y-2 p-4 h-[390px] w-[270px] lg:h-[370px] lg:w-[280px] xl:w-[350px] border-4 rounded-lg border-[#3B3A5D]"
          >
            <h1 class="text-2xl font-bold text-center lg:text-start xl:text-center">Wellness and Leisure</h1>
            <div class="text-[#393939]">
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">Fully equipped gymnasium</p>
              </span>
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">Sauna and steam rooms</p>
              </span>
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">Yoga and meditation spaces</p>
              </span>
              <span class="flex space-x-2 items-center">
                <img src="image/pic1.png" alt="picture" class="w-5 h-5" />
                <p class="text-lg">Lush landscaped gardens with jogging and walking tracks</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>

      <h2 className="text-4xl text-center my-10 font-semibold">
        Location Advantages
      </h2>
      <div className="flex justify-center mt-16 p-4 space-x-8">
        {/* Connectivity Section */}
        <div className="w-1/3 p-4 bg-white rounded">
  <h2 className="text-2xl font-bold mb-4">Connectivity</h2>
  <div className="space-y-6">
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">15 Minutes – Falconcity of Wonders, Dubai</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">15 Minutes – Zayed University</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">10 Minutes – Lulu Hypermarket, Silicon Oasis</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">25 Minutes - Dubai International Airport</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">15 Minutes – IMG Worlds of Adventure</p>
    </div>
  </div>
</div>

        {/* Image Section */}
        <div className="w-1/5 p-4 flex justify-center bg-white rounded">
          <img src={Location} alt="Placeholder" className="rounded" />
        </div>

        {/* Neighborhood Section */}
        <div className="w-1/3 p-4 bg-white rounded">
  <h2 className="text-2xl font-bold mb-4">Neighborhood</h2>
  <div className="space-y-6">
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
      Located in Dubailand, providing a serene yet well-connected lifestyle.      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        A family-oriented community that offers a safe and welcoming atmosphere.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
      Close to major entertainment hubs and shopping destinations.      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Perfect for those looking to settle in a comfortable and serene environment.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Conveniently close to essential amenities like shopping, dining, and recreational facilities.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
      Easy access to key roads and transport links for seamless connectivity.      </p>
    </div>
  </div>
</div>

      </div>

      <div className="bg-[#f5f5f5] py-10">
  <h2 className="text-3xl md:text-4xl mb-10 font-bold text-center">
    Design and Architecture
  </h2>

  {/* Content Section */}
  <div className="flex flex-col md:flex-row justify-center items-center w-11/12 mx-auto gap-6">
    <div className="p-4 w-full md:w-1/2">
      <p className="text-lg md:text-xl text-black">
        The architectural design of Ivy Gardens is a testament to Samana Developers’ commitment to excellence. The buildings feature sleek, contemporary lines, complemented by lush vertical gardens and beautifully landscaped outdoor areas. Floor-to-ceiling windows and open layouts ensure each unit is filled with natural light, enhancing the sense of space and connection to the outdoors. The inclusion of private pools, spacious balconies, and rooftop leisure facilities creates a unique and luxurious living environment. Sustainable features, including energy-efficient systems and water conservation technologies, make Ivy Gardens a forward-thinking development that blends elegance with eco-conscious living.
      </p>
    </div>

    <div className="flex justify-center w-full md:w-1/2">
      <img src={DA} alt="Placeholder" className="rounded w-full md:w-auto" />
    </div>
  </div>

  {/* Download Button */}
  <div className="flex justify-center">
    <button
      className="px-6 py-3 bg-[#D8B56D] uppercase text-white rounded-3xl mt-10 w-full sm:w-96 flex items-center justify-center"
      onClick={() => setShowPopup(true)}
    >
      Download Brochure <img className="ml-3" src={pdf} alt="PDF icon" />
    </button>
  </div>

  {/* Popup Modal */}
  {showPopup && (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 sm:p-8 md:p-10 w-full sm:w-3/4 md:w-1/3 h-auto relative">
        <button className="absolute top-4 right-4 text-gray-600 text-xl" onClick={() => setShowPopup(false)}>
          &times;
        </button>
        <div className="py-8 sm:py-10 flex flex-col justify-between h-full">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">DOWNLOAD</h2>
          <h3 className="text-2xl sm:text-3xl text-[#000] font-bold mb-6">
            THE BROCHURE
          </h3>
          <p className="mb-6 text-lg">Enter your details to get your copy.</p>

          {/* Form fields */}
          {["name", "email", "phone"].map((field, index) => (
            <div key={index} className="mb-3">
              <input
                type="text"
                name={field}
                placeholder={field.toUpperCase()}
                value={formValues[field]}
                onChange={handleChange}
                className={`w-full p-3 border ${
                  errors[field] ? "border-red-500" : "border-gray-300"
                } rounded`}
              />
              {errors[field] && (
                <p className="text-red-500 text-sm">Please fill this field.</p>
              )}
            </div>
          ))}

          {/* Download button */}
          <button className="w-full mt-6 bg-[#D8B56D] uppercase text-white p-3 rounded" onClick={handleDownload}>
            Download
          </button>

          <p className="mt-6 text-lg text-gray-600">
            By submitting this form you agree to subscribe to updates from PointX Realty and be sent information in relation to its products and services.
          </p>
        </div>
      </div>
    </div>
  )}
</div>

{/* Contact & Map Section */}
<div className="flex flex-col lg:flex-row w-full bg-gradient-to-r from-[#000] via-[#000] to-[#000] py-10">
  <div className="w-full lg:w-1/2 bg-white p-4 rounded-t-2xl lg:rounded-l-2xl">
    <div className="w-11/12 mx-auto p-6">
      <img alt="" src={touch} className="mb-4 w-24 sm:w-32 md:w-40" />
      <form className="pb-10">
        {["name", "email", "phone"].map((field, index) => (
          <div key={index} className="mb-4">
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              id={field}
              type="text"
              placeholder={field.toUpperCase()}
            />
          </div>
        ))}

        <button className="bg-[#D8B56D] w-full text-white font-bold uppercase py-3 px-4 rounded" type="button">
          SEND
        </button>
      </form>
      <img src={info} alt="" className="w-full" />
    </div>
  </div>

  {/* Map Section */}
  <div className="w-full lg:w-1/2 flex justify-center items-center p-4 bg-white rounded-b-2xl lg:rounded-r-2xl">
    <div className="w-full sm:w-10/12 lg:w-[470px] p-4 rounded shadow-md">
      <div className="rounded overflow-hidden shadow-lg">
        <iframe
          className="w-full h-[250px] sm:h-[350px] md:h-[400px] lg:h-[470px] rounded"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.168505642546!2d55.173861210957384!3d25.096156677683474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b798cbfff8b%3A0x70a7f5e105a580e!2sDAMAC%20Smart%20Heights!5e0!3m2!1sen!2sus!4v1728393772385!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
};

export default Ivy;
