import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../Images/Logo.png";
import Hero1 from "./ParkvilleImages/1.png";
import Hero2 from "./ParkvilleImages/2.png";
import Amenities from "./ParkvilleImages/4.png";
import Gallery1a from "./ParkvilleImages/Gallery1a.webp";
import Gallery2a from "./ParkvilleImages/Gallery2a.jpg";
import Gallery3a from "./ParkvilleImages/Gallery3a.jpeg";
import Gallery4a from "./ParkvilleImages/Gallery4a.webp";
import Gallery5a from "./ParkvilleImages/Gallery5a.webp";
import BulletImage from "./ParkvilleImages/Bullet.png";
import Location from "./ParkvilleImages/location.png";
import DA from "./ParkvilleImages/DA.png";
import pdf from "./ParkvilleImages/pdf.png";
import touch from "./ParkvilleImages/touch.png";
import info from "./ParkvilleImages/info.png";
import Footer from "../../WhyDubaiComps/Footer";
// import Brochure from "./Parkville.pdf";

import useScrollToTop from "../../Scroll";

const Parkville = () => {
  useScrollToTop();

  const alternateImages = [
    Gallery1a,
    Gallery2a,
    Gallery3a,
    Gallery4a,
    Gallery5a,
  ];
  const NextArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &gt;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &lt;
    </div>
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const handleDownload = () => {
    const newErrors = {
      name: !formValues.name,
      email: !formValues.email,
      phone: !formValues.phone,
    };

    setErrors(newErrors);

    // If there are no errors, proceed with download
    if (!newErrors.name && !newErrors.email && !newErrors.phone) {
      const link = document.createElement("a");
      // link.href = Brochure; // Use the imported PDF file here
      link.download = "ParkvilleBrochure.pdf"; // You can set the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("Download initiated");
      alert("Thanks for contacting us. Our team will be in touch with you soon.");
      setFormValues({
        name: "",
        email: "",
        phone: "",
      });
      setShowPopup(false);
    }
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openModal = () => {
    setIsGalleryOpen(true);
  };

  const closeModal = () => {
    setIsGalleryOpen(false);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <div className="relative w-full ">
        {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>    <div>
          {/* Section that triggers the popup */}
          <section className="w-full h-full cursor-pointer" onClick={openModal}>
            <img src={Hero1} alt="" className="w-full h-full object-cover" />
          </section>

          {/* Popup Modal */}
          {isGalleryOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
              <div className="relative w-11/12 max-w-6xl bg-white p-6">
                {/* Close Button */}
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  &#x2715; {/* Cross icon */}
                </button>

                {/* Slider */}
                 <Slider {...sliderSettings}>
                  {alternateImages.map((image, index) => (
                    <div key={index} className="w-full h-auto">
                      <img
                        src={image}
                        alt={`Alternate Iage ${index + 1}`}
                        className="w-full h-[90vh] "
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-5 flex justify-end w-10/12 mx-auto relative">
        <Link
          to={"/Samana#top"}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl mr-10 text-white"
        >
          GO Back
        </Link>
        <button
          onClick={togglePopup}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl text-white"
        >
          Pricing Plan
        </button>
        {isOpen && (
          <div className="absolute top-full z-10 right-0 mt-2 bg-white shadow-lg rounded-lg p-4 w-96">
            <h3 className="text-xl font-bold mb-2">Payment Plans</h3>
            <ul className="list-disc pl-5">
              <li className="bg-transparent text-black text-lg">
                15/85, (1% Monthly)
              </li>
              <li className="bg-transparent text-black text-lg">
                Down payment: 15% AED 674,000{" "}
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="bg-[#F5F5F5] pt-10">
        <h1 className="text-5xl font-semibold text-center">
         Parkville by Samana Developers
        </h1>
        <p className="w-10/12 mx-auto text-2xl text-left my-10">
        Samana Parkville is a prestigious residential development by Samana Developers, strategically located in the vibrant community of Arjan, Dubai. This architectural masterpiece offers a range of luxury studios, one-, two-, and three-bedroom apartments, as well as three-bedroom penthouses, each thoughtfully designed to provide residents with an unparalleled living experience. With its modern design, premium amenities, and prime location, Samana Parkville redefines contemporary urban living.

        </p>
        <div className="flex justify-center items-center ">
          <img src={Hero2} alt="" />
        </div>
        <div className="bg-custom-gradient  ">
          <h1 className="text-center text-4xl font-semibold py-10">
            Amenities
          </h1>
          <img className="mx-auto pb-10   " src={Amenities} alt="" />
        </div>
      </div>

      <h2 className="text-4xl text-center my-10 font-semibold">
        Location Advantages
      </h2>
      <div className="flex justify-center mt-16 p-4 space-x-8">
        {/* Connectivity Section */}
        <div className="w-1/3 p-4 bg-white rounded">
  <h2 className="text-2xl font-bold mb-4">Connectivity</h2>
  <div className="space-y-6">
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">05 Minutes - Miracle Garden</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">05 Minutes - Butterfly Garden</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">10 Minutes - First Avenue Mall</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">14 Minutes - Mall of the Emirates</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">15 Minutes - Dubai Hills Mall</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">20 Minutes - Sheikh Zayed Road</p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">25 Minutes - Dubai International Airport</p>
    </div>
  </div>
</div>



        {/* Image Section */}
        <div className="w-1/5 p-4 flex justify-center bg-white rounded">
          <img src={Location} alt="Placeholder" className="rounded" />
        </div>

        {/* Neighborhood Section */}
        <div className="w-1/3 p-4 bg-white rounded">
  <h2 className="text-2xl font-bold mb-4">Neighborhood</h2>
  <div className="space-y-6">
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Situated in the heart of Arjan, offering easy access to major roads and the wider city.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Walking distance to Dubai's renowned attractions, including the Miracle Garden and Butterfly Garden.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Proximity to educational institutions, healthcare facilities, retail outlets, and dining options.
      </p>
    </div>
    <div className="flex items-start">
      <img
        src={BulletImage}
        alt="Bullet Point"
        className="w-4 h-4 mr-3 mt-2"
      />
      <p className="text-black text-xl my-1">
        Family-friendly environment with ample green spaces and recreational areas.
      </p>
    </div>
  </div>
</div>



      </div>

      <div className="bg-[#f5f5f5] py-10">
  <h2 className="text-3xl md:text-4xl mb-10 font-bold text-center">
    Design and Architecture
  </h2>

  {/* Content Section */}
  <div className="flex flex-col md:flex-row justify-center items-center w-11/12 mx-auto gap-6">
    <div className="p-4 w-full md:w-1/2">
      <p className="text-lg md:text-xl text-black">
        The development features a contemporary low-rise design, comprising a
        6-storey façade that seamlessly integrates with the natural beauty of
        Dubai Islands. Each apartment is designed with expansive glass windows,
        allowing for ample natural light and offering breathtaking views of the
        waterfront. Interiors are adorned with premium finishes, European solid
        core doors, and high-quality fixtures from renowned brands, creating an
        ambiance of sophistication and comfort. Select units come with private
        pools, providing an exclusive retreat within the residence.
      </p>
    </div>

    <div className="flex justify-center w-full md:w-1/2">
      <img src={DA} alt="Placeholder" className="rounded w-full md:w-auto" />
    </div>
  </div>

  {/* Download Button */}
  <div className="flex justify-center">
    <button
      className="px-6 py-3 bg-[#D8B56D] uppercase text-white rounded-3xl mt-10 w-full sm:w-96 flex items-center justify-center"
      onClick={() => setShowPopup(true)}
    >
      Download Brochure <img className="ml-3" src={pdf} alt="PDF icon" />
    </button>
  </div>

  {/* Popup Modal */}
  {showPopup && (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 sm:p-8 md:p-10 w-full sm:w-3/4 md:w-1/3 h-auto relative">
        <button className="absolute top-4 right-4 text-gray-600 text-xl" onClick={() => setShowPopup(false)}>
          &times;
        </button>
        <div className="py-8 sm:py-10 flex flex-col justify-between h-full">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">DOWNLOAD</h2>
          <h3 className="text-2xl sm:text-3xl text-[#000] font-bold mb-6">
            THE BROCHURE
          </h3>
          <p className="mb-6 text-lg">Enter your details to get your copy.</p>

          {/* Form fields */}
          {["name", "email", "phone"].map((field, index) => (
            <div key={index} className="mb-3">
              <input
                type="text"
                name={field}
                placeholder={field.toUpperCase()}
                value={formValues[field]}
                onChange={handleChange}
                className={`w-full p-3 border ${
                  errors[field] ? "border-red-500" : "border-gray-300"
                } rounded`}
              />
              {errors[field] && (
                <p className="text-red-500 text-sm">Please fill this field.</p>
              )}
            </div>
          ))}

          {/* Download button */}
          <button className="w-full mt-6 bg-[#D8B56D] uppercase text-white p-3 rounded" onClick={handleDownload}>
            Download
          </button>

          <p className="mt-6 text-lg text-gray-600">
            By submitting this form you agree to subscribe to updates from PointX Realty and be sent information in relation to its products and services.
          </p>
        </div>
      </div>
    </div>
  )}
</div>

{/* Contact & Map Section */}
<div className="flex flex-col lg:flex-row w-full bg-gradient-to-r from-[#000] via-[#000] to-[#000] py-10">
  <div className="w-full lg:w-1/2 bg-white p-4 rounded-t-2xl lg:rounded-l-2xl">
    <div className="w-11/12 mx-auto p-6">
      <img alt="" src={touch} className="mb-4 w-24 sm:w-32 md:w-40" />
      <form className="pb-10">
        {["name", "email", "phone"].map((field, index) => (
          <div key={index} className="mb-4">
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              id={field}
              type="text"
              placeholder={field.toUpperCase()}
            />
          </div>
        ))}

        <button className="bg-[#D8B56D] w-full text-white font-bold uppercase py-3 px-4 rounded" type="button">
          SEND
        </button>
      </form>
      <img src={info} alt="" className="w-full" />
    </div>
  </div>

  {/* Map Section */}
  <div className="w-full lg:w-1/2 flex justify-center items-center p-4 bg-white rounded-b-2xl lg:rounded-r-2xl">
    <div className="w-full sm:w-10/12 lg:w-[470px] p-4 rounded shadow-md">
      <div className="rounded overflow-hidden shadow-lg">
        <iframe
          className="w-full h-[250px] sm:h-[350px] md:h-[400px] lg:h-[470px] rounded"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.168505642546!2d55.173861210957384!3d25.096156677683474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b798cbfff8b%3A0x70a7f5e105a580e!2sDAMAC%20Smart%20Heights!5e0!3m2!1sen!2sus!4v1728393772385!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>
    </div>
  </div>
</div>


      <Footer />
    </div>
  );
};

export default Parkville;
